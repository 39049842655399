<template>
  <div class="content md">
    <data-list
      ref="dataList"
      url="/admin/doorman-message"
      query="&sort=-id&expand=apply.user&filter[position]=3"
      entity-name="车牌"
      hide-action
      :add-btn="false"
      :custom-url="customUrl"
      :deal-list="dealList"
      :columns="columns"
      :search-key-type="{num: 1}"
      :default-query="{date: new Date().pattern('yyyy-MM-dd')}"
      :page-size="30"
      style="height: 100%;">
      <template v-slot:button>
        <a-button type="primary" @click="showModal">车牌下发</a-button>
      </template>
      <template v-slot:query="{form}">
        <a-form-model-item label="进入日期">
          <a-date-picker
            v-model="form.date"
            placeholder="选择日期"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="updateList" />
        </a-form-model-item>
        <a-form-model-item label="">
          <a-input-search v-model="form.num" placeholder="输入车牌查询" @search="updateList"></a-input-search>
        </a-form-model-item>
      </template>
    </data-list>
    <a-modal
      title="车牌下发"
      :ok-text="loading ? '正在下发' : '下发'"
      :ok-button-props="{props: {loading}}"
      :width="480"
      @ok="confirm"
      v-model="visible">
      <form-area
        ref="plateForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 18}"
        :items="items"
        :entity="form"
        hide-btn></form-area>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import {constructForm, plateForm} from "@/common/js/apply";
import {getUserWxId} from "@/common/js/storage";
import {getDate, randomString} from "@/common/js/tool";

const checkNumber = function (number) {
    // 普通车牌
    const normal = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{5,6}$/u;
    // 特种车牌(学、挂、领、试、超、练、警)
    const special = /^[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]{1}[ABCDEFGHJKLMNPQRSTUVWXY]{1}[0-9A-Z]{4}[学挂领试超练警]{1}$/u;
    // 武警车牌
    const police = /^WJ[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]?[0-9A-Z]{5}$/ui;
    // 军牌
    const army = /^[QVKHBSLJNGCEZ]{1}[ABCDEFGHJKLMNPQRSTUVWXY]{1}[0-9A-Z]{5}$/u;
    return normal.test(number) || special.test(number) || police.test(number) || army.test(number);
}

export default {
  name: "TemplatePlateComp",
  data() {
    return {
        form: {},
        columns: [
            {title: '下发时间', dataIndex: 'create_time'},
            {title: '车牌号', dataIndex: 'num'},
            {
                title: '联系人',
                dataIndex: 'name',
                customRender: (text, record) => text ? text : record.apply?.user_id ? <open-data openid={record.apply.user_id} type="userName"></open-data> : "--"
            },
            {title: '联系电话', dataIndex: 'tel'},
        ],
        items: [
            {
                key: 'car_list',
                label: '车辆',
                component: 'item-list',
                props: {
                    addTitle: "添加车辆",
                    format: val => val ? val.replace(/\s/g, "").toLocaleUpperCase() : '',
                    validator: val => checkNumber(val) ? null : '车牌号格式有误，请重新输入'
                },
                rules: [{ required: true, message: '请添加车辆', trigger: 'change' }]
            },
            {
                key: 'enter_time',
                label: '进入时间',
                component: 'half-date-picker',
                props: {
                    placeholder: '请选择进入时间',
                },
                rules: [{ required: true, message: '请选择进入时间', trigger: 'change' }]
            },
            {
                key: 'leave_time',
                label: '离开时间',
                component: 'half-date-picker',
                props: {
                    placeholder: '请选择离开时间',
                },
                rules: [{ required: true, message: '请选择离开时间', trigger: 'change' }]
            }
        ],
        loading: false,
        visible: false
    }
  },
  computed: {
    info() {
      return this.$store.getters.temp;
    }
  },
  methods: {
      customUrl(form) {
          let res = "";
          if (form.num) {
             res += `&filter[num][like]=${form.num}`
          }
          if(form.date) {
              res += `&filter[real_date]=${form.date}`;
          }
          return res;
      },
      dealList(list, fn) {
          list.forEach(item => {
              if(item.apply) {
                  if(item.apply.detail) {
                      const detail = JSON.parse(item.apply.detail);
                      const carList = detail.car_list;
                      if(carList && carList.length > 0) {
                          const car = carList.find(c => c.num == item.num);
                          if(car) {
                              item.name = car.name;
                              item.tel = car.tel;
                          }
                      }
                  }
                  // 表单未填联系人手机号码
                  if(!item.tel && item.apply.user) {
                      item.tel = item.apply.user.mobile || "--"
                  }

              }
          });
          fn(list);
      },
      disabledDate(current) {
          return current && current > moment().endOf('day');
      },
      updateList() {
          this.$refs.dataList.getList();
      },
      showModal() {
          this.form = {};
          this.visible = true;
      },
      confirm() {
          this.$refs.plateForm.handleConfirm().then(val => {
              const st = getDate(val.enter_time[0]).getTime() + val.enter_time[1];
              const et = getDate(val.leave_time[0]).getTime() + val.leave_time[1];
              if(st > et) {
                  this.$message.warning("进入时间不能大于离开时间");
                  return;
              }
              // this.loading = true;
              const value = {
                  car: val.car_list,
                  car_list: val.car_list ? val.car_list.split(",").map(num => new Object({num})) : [],
                  "half-date_3KWWrDf5": val.enter_time,
                  "half-date_sWCiTn2k": val.leave_time
              }
              const form = [...plateForm];
              const uid = getUserWxId();
              const flow = JSON.stringify([
                  {
                      type: 2,
                      custom_cc: 0,
                      self_cc: 0,
                      leader_option: 0,
                      title: "抄送人",
                      node_id: `cc_${randomString(6)}`,
                      ids: [uid]
                  },
              ]);
              let detailForm = [];
              constructForm(detailForm, value, form);
              const data = {
                  user_id: uid,
                  temp_id: this.info.id,
                  car_num_type: 2,
                  car_num_status: 0,
                  detail: JSON.stringify(value),
                  temp_snap: JSON.stringify(form),
                  temp_flow_snap: flow,
                  real_flow: flow,
                  export_detail: JSON.stringify(detailForm)
              }
              this.$axios({
                  url: "/admin/apply",
                  method: "POST",
                  data
              }).then(() => {
                  this.$message.success('已下发');
                  this.visible = false;
              }).finally(() => this.loading = false);
          });
      }
  }
}
</script>

<style lang="less">
.set-info-label {
  min-width: 100px;
  color: @text-color-secondary;
}
.doorman-item {
  display: inline-block;
  margin-right: 0.5em;
  padding: 2px 6px;
  border: 1px solid #E4E6E9;
  border-radius: 2px;
  line-height: 1;
}
.doorman-icon {
  margin-right: 6px;
  color: @primary-color;
}
.doorman-edit {
  display: inline-block;
  cursor: pointer;
  color: @primary-color;
}
</style>
